import Api from './Api'

export default {
    // New Saif
    getOrgByID(payload) {
        return Api().post('api/dashuser/GetOrganizationByID', payload)
    },
    addOrganization(body) {

        return Api().post('api/dashuser/AddOrg', body)
    },
    getOrganization() {
        return Api().post('api/dashuser/GetOrgList')
    },
    getOrganizationDDL() {
        return Api().post('api/dashuser/GetddlOrgList')
    },
}