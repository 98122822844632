import Api from "./Api";

export default {
  // New Saif
  getScreenByID(payload) {
    return Api().post("api/dashuser/GetScreenByID", payload);
  },
  addScreen(payload) {
    return Api().post("/api/dashuser/AddScreen", payload);
  },
  getScreens(payload) {
    return Api().post("api/dashuser/GetScreenList", payload);
  },
  addSeatLayout(body) {
    return Api().post("/api/dashuser/AddSeatLayout", body);
  },
  getScreensDDL(payload) {
    return Api().post("api/dashuser/GetScreenddl", payload);
  },
  getScreenSeatLayoutData(payload) {
    return Api().post("api/dashuser/GetScreenSeatLayout/", payload);
  },
};
